<template>

  <div class="mainContain">
    <div class="box">
      <div class="list">
        <div :class="[!userInfo.gptsUserPhone ? 'error' : '', 'item']">
          <div class="icon">
            <img loading="lazy" src="@/assets/secure1.png" alt="" />
          </div>
          <div class="content">
            <div class="title">手机号绑定</div>
            <p class="text2" v-if="userInfo.gptsUserPhone">
              已绑定手机号<span class="red">{{ userInfo.gptsUserPhone }} </span
            >，绑定手机号可以快速找回密码和验证身份，保证您的账号安全
            </p>
            <p class="text1" v-else>您暂未进行手机绑定</p>
          </div>

          <div class="button" @click="toPhone()">
            <span v-if="userInfo.gptsUserPhone">立即修改</span>
            <span v-else>立即绑定</span>
          </div>
        </div>
        <div :class="[!userInfo.gptsUserEmail ? 'error' : '', 'item']">
          <div class="icon">
            <img loading="lazy" src="@/assets/secure2.png" alt="" />
          </div>
          <div class="content">
            <div class="title">邮箱绑定</div>
            <p class="text2" v-if="userInfo.gptsUserEmail">
              已绑定邮箱号<span class="red">{{ userInfo.gptsUserEmail }}</span
            >，绑定邮箱可以快速找回密码和验证身份，保证您的账号安全
            </p>
            <p class="text1" v-else>您暂未进行邮箱绑定</p>
          </div>

          <div class="button" @click="toEmail()">
            <span v-if="userInfo.gptsUserEmail">立即修改</span>
            <span v-else>立即绑定</span>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img loading="lazy" src="@/assets/secure3.png" alt="" />
          </div>
          <div class="content">
            <div class="title">登录密码</div>
            <p class="text1">
              安全性高的密码可以使账号更安全，建议您定期更换密码
            </p>
          </div>

          <div class="button" @click="toPassword()">
            <span>立即修改</span>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img loading="lazy" src="@/assets/secure4.png" alt="" />
          </div>
          <div class="content">
            <div class="title">微信解绑</div>
            <p class="text2" v-if="isBind">您已绑定微信，点击解除绑定</p>
            <p class="text1" v-else>
              您暂未进行微信绑定 请前往登录页面进行绑定
            </p>
          </div>

          <div class="button" @click="toWeixin()" v-if="isBind">
            <span>立即解绑</span>
          </div>
        </div>
      </div>
      <el-dialog
          title="温馨提示"
          :visible.sync="dialogVisible"
          width="480px"
          :append-to-body="true"
          :before-close="handleClose"
          top="40vh"
      >
        <span>是否确认解除绑定，解除绑定后，无法通过微信进行登录</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose"> 取消 </el-button>
          <el-button type="primary" @click="handTo"> 确认 </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { unbindAccount, social } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
export default {
  name: "Secure",
  components: {},
  data() {
    return {
      changeVisable:1,
      baseURL: this.$utils.baseURL,
      userInfo: localStorage.getItem("userInfo"),
      dialogVisible: false,
      languageSelect: "",
      isBind: false,
    };
  },
  computed: {},
  watch: {
    "$store.state.user"(newValue) {
      this.userInfo = newValue;
    },
  },
  created() {

    // console.log(this.userInfo)
    this.userInfo=JSON.parse(this.userInfo)
    // console.log(this.userInfo.email)
    this.social();
    let url = window.location.host;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气人才",
        text: "洞悉油气行业变化，体验AI大模型，就来油气人才！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  mounted() {},
  methods: {
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    toPhone() {
      if (this.userInfo.phonenumber) {
        this.$router.push({ path: "/changePhone", query: { type: 1 } });
      } else {
        this.$router.push("/changePhone");
      }
    },
    toEmail() {
      if (this.userInfo.phonenumber) {
        this.$router.push({ path: "/changeEmail", query: { type: 1 } });
      } else {
        this.$router.push("/changeEmail");
      }
    },
    toPassword() {
      this.$router.push("/changePassword");
    },
    toWeixin() {
      this.dialogVisible = true;
    },
    social() {
      let data = {
        language: "zh",
        access_token: localStorage.getItem("Zyaccess_token"),
      };
      social(data).then((res) => {
        console.log(res);
        if (res.data.isBind) {
          this.isBind = res.data.isBind;
          this.socialUid = res.data.socialUid;
        } else {
          this.isBind = false;
          // if (this.languageSelect == "zh") {
          //   this.$message.error("您暂未进行微信绑定 请前往登录页面进行绑定");
          // } else {
          //   this.$message.error(
          //     "You have not yet bound to WeChat. Please go to the login page to bind"
          //   );
          // }
        }
      });
    },
    handTo() {
      let data = {
        access_token: localStorage.getItem("Zyaccess_token"),
        socialUid: this.socialUid,
      };
      unbindAccount(data).then(() => {
        this.dialogVisible = false;
        this.socialUid = null;
          this.$message({
            message: "解绑成功!", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              location.reload();
            },
          });
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>


<style type="text/scss" lang="scss" scoped>
.mainContain{
  width: 65vw;
  border-radius: 9px;
  margin:0 1vw;
  background-color: white;
  height: 75vh;
  padding: 0 2vw 3vh 0;
  position: relative;
  background-image:url("../../assets/oilba.png");
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  background-color: transparent;
  .box {
    .ViewTitle {
      height: 67px;
      line-height: 67px;
      border-bottom: 1px solid #c0bebe;
      font-size: 20px;
      color: #333333;
      font-weight: 400;
      padding: 0 30px;
    }

    .list {
      padding: 0 3vw;
      .item {
        padding: 5vh 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width:4vh;
          height: 4vh;
          margin-right: 2vw;
        }
        .content {
          flex: 1;
          width: 0;
          .title {
            font-size: 1vw;
            color: #222222;
            margin-bottom: 1vh;
          }
          p {
            font-size: 0.9vw;
            color: #333333;
          }
        }
        .button {
          width: 7vw;
          height: 6vh;
          line-height: 6vh;
          align-items: center;
          justify-content: center;
          text-align: center;
          border: 1px solid var(--main-color) ;
          color: #333333;
          border-radius: 9px;
          font-size: 0.9vw;
          cursor: pointer;
        }
      }
      .item:not(:last-child) {
        border-bottom: 1px solid #ebebeb;
      }
    }
  }
  ::v-deep {
    .el-dialog__header {
      border-bottom: 1px solid #ebebeb;
      .el-dialog__title {
        font-weight: bold;
        font-size: 16px;
      }
    }
    .el-button {
      width: 100px;
      height: 28px;
      line-height: 28px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      border: 1px solid var(--main-color);
      color: var(--main-color);
      padding: 0;
    }
    .el-button--primary {
      border: 1px solid var(--main-color);
      background: var(--main-color);
      color: #fff;
    }
    .el-button:focus,
    .el-button:hover {
      color: var(--main-color);
      border-color: var(--main-color);
      background-color: #fff;
    }
    .el-button--primary:focus,
    .el-button--primary:hover {
      background: var(--main-color);
      border-color: var(--main-color);
      color: #fff;
    }
    .el-dialog__headerbtn:focus .el-dialog__close,
    .el-dialog__headerbtn:hover .el-dialog__close {
      color: var(--main-color);
    }
  }
  .red {
    color: var(--main-color) !important;
  }
}

</style>
